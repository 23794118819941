/*
    mixin使用注意：
        1、不同组件调用这个mixin，data中的值会设为初始值，比如组件1使用了自增了num，那么组件2调用的时候，num的值依旧是初始值，不会受组件1的调用而改变
        2、created执行顺序，如果在mixin里写了created方法，组件会先调用mixin函数里的created周期函数，再去调用组件里的created周期函数(由于我在mixin函数里加了async awaait这个方法，组件的created函数不会等待mixincreated函数跑完promise异步代码再去执行，所以会先执行组件里边的created钩子函数)
        3、methods函数，如果mixin函数命名与组件函数命名一样，只会调用组件的函数，不会调用mixin的函数
*/
import proxy from "@/api/proxy"
import { format } from "@/utils/formatTime.js"
import { decrypt, encrypt } from "@/utils/decrypt.js"
export const myMixin = {
    data() {
        return {
            // 三级审核
            buttonRoot: "",
            status: "",

            // 左侧菜单第几项
            activeMenu: "",

            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0,

            // 菜单数据
            menuCodeObj: {},

            // 顶部搜索的数据
            searchData: {},
            resetNum: 0,

            // 存放获取的数量
            auditNumber: 0,
            rejectNumber: 0,
            waitNumber: 0,

            // 设为模板按钮权限
            isManager: "",

            functionType: "",

            // 操作弹窗
            caozuoStatus: false,

            caozuoList: [],

            caojiList: [],

            caozuoInfo: {},
            dimensionId: "",

            channelList: [
                { label: "0", value: "全部", select: "" },
                { label: "1", value: "TV端", select: "" },
                { label: "3", value: "APP端", select: "" },
                { label: "4", value: "小程序", select: "" },
            ],

            setContent: {
                terminal: [],
                recommend: "",
                contactMobile: "",
                pushType: [],
                groupNames: "",
                channels: "",
                tipsCodeList: [],
            },
            groupStatus: false,

            typeList: [],

            showImage: true,
            defaultListIcon: [],
            imageDesc: "",

            sendGroupStatus: false,
            grouptalbeColumns: [],
            grouptableData: [],
            grouppage: 1,
            grouptotal: 0,
            grouploading: false,
            grouppageSize: 20,

            groupList: [],
            sendGroup: {
                pushChannel: "",
            },

            coreData: {
                custNum: 0,
                userNum: 0,
                viewNum: 0,
                registerNum: 0,
            },

            rightList: [],
            groupSelect: [],
            removeRightList: [],

            selectList: [],
        }
    },
    async created() {
        this.dimensionId = window.sessionStorage.getItem("dimensionId")
        if (this.$route.query.columnCode && this.$route.query.functionType) {
            if (this.$route.query.type) {
                this.getRoot()
                await this.getMenuList()
                await this.getList()
                return
            }
            if (this.$route.query.templateId && this.$route.query.templateId != "") {
                this.menuCodeObj = {
                    functionType: this.$route.query.functionType,
                    columnCode: this.$route.query.columnCode,
                }
                this.indexQuote()
                return
            }
            let status = this.$route.query.status
            if (status) {
                if (status == 2) {
                    // 修改逻辑
                    this.editChange()
                    return
                } else if (status == 1 || status == 3 || status == 4 || status == 8) {
                    // 审核逻辑
                    this.shenheChange()
                    return
                } else if (status == 5) {
                    // 复制逻辑
                    this.copyChange()
                    return
                } else if (status == 6) {
                    // 引用逻辑
                    this.sendChange(JSON.parse(window.sessionStorage.getItem("quoteData")))
                    return
                } else if (status == 7) {
                    // 查看逻辑审核
                    this.checkChange()
                    return
                }
            }
            // 新增逻辑
            this.addChange()
            return
        }
        this.getRoot()
        await this.getMenuList()
        await this.indexQuote()
        await this.getList()
        // await this.getCount()
        // 处理首页发布——跳审核发布
        if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status) {
            switch (this.$route.query.status) {
                case "1":
                    this.titleName = "审核"
                    this.affairsType = "examine"
                    break
                case "2":
                    this.titleName = "修改"
                    this.affairsType = "edit"
                    break
                case "3":
                    this.titleName = "发布"
                    this.affairsType = "send"
                    break
                case "4":
                    this.titleName = "过期"
                    this.affairsType = "old"
                    break
            }
            this.getDetail(this.$route.query.id)
        }
    },
    methods: {
        pushToRightList() {
            if (this.selectList.length == 0) {
                return this.$Message.warning({
                    content: "请勾选数据",
                    background: true,
                })
            }
            let data = []
            this.selectList.map((el, index) => {
                if (!this.rightList.find(item => item.groupId == el.groupId)) {
                    data.push(this.selectList[index])
                }
            })
            this.rightList.push(...data)
            this.$refs.lftable.$refs.lftable.selectAll(false)
        },
        // 选中右侧
        selectRightList(e, index) {
            if (e.target.parentNode.classList.contains("active")) {
                e.target.parentNode.classList.remove("active")
                this.removeRightList.splice(index, 1)
            } else {
                e.target.parentNode.classList.add("active")
                this.removeRightList.push(this.rightList[index])
            }
        },
        delRightList() {
            if (this.removeRightList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请从已选择列表中选中需要删除群",
                })
                return
            }
            let data = []
            this.rightList.map((el, index) => {
                if (!this.removeRightList.find(item => item.groupId == el.groupId)) {
                    data.push(this.rightList[index])
                }
            })
            this.rightList = []
            setTimeout(() => {
                this.rightList = data
            }, 10)
            this.removeRightList = []
        },
        clearRightList() {
            this.rightList = []
        },
        grouptableSelect(val) {
            this.selectList = val
        },
        // 群分页
        grouphadlePageSize(val) {
            this.grouppage = val.page
            this.grouppageSize = val.pageSize
            if (this.sendGroup.pushChannel && this.sendGroup.pushChannel == "7") return
            if(this.sendGroup.pushChannel && this.sendGroup.pushChannel == "10"){
                this.selectAppByOrgCode()
                return
            }
            this.getGroupList()
        },

        // 关闭推送
        changeGroupStatus2(val) {
            this.sendGroupStatus = val
        },
        // 保存按钮
        saveSendGroup() {
            if (!this.sendGroup.pushChannel || this.sendGroup.pushChannel == "") {
                this.$Message.warning({
                    content: "请选择推送对象设置",
                    background: true,
                })
                return
            }

            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>是否进行精准推送</p>",
                onOk: () => {
                    var List = []
                    this.groupSelect = this.rightList
                    if (this.sendGroup.pushChannel == "3" || this.sendGroup.pushChannel == "7" || this.sendGroup.pushChannel == "10") {
                        if (!this.groupSelect || this.groupSelect.length == 0) {
                            this.$Message.warning({
                                content: this.sendGroup.pushChannel == "3" ? "请选择群" : "请选择报表",
                                background: true,
                            })
                            return
                        } else {
                            this.groupSelect.map(item => {
                                List.push(item.groupId)
                            })
                        }
                    }
                    this.$Message.loading({
                        content: "正在保存，请稍等",
                    })

                    this.$post(
                        "/gateway/syinfopublish/api/pc/information/v2/informationPUSH",
                        {
                            informationId: this.caozuoInfo.id,
                            communityCode: JSON.parse(sessionStorage.getItem("userDetail")).communityCode,
                            pushChannel: this.sendGroup.pushChannel,
                            groupList: this.sendGroup.pushChannel && (this.sendGroup.pushChannel == "3" || this.sendGroup.pushChannel == "7" || this.sendGroup.pushChannel == "10") ? List : [],
                            orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            oemCode: parent.vue.oemInfo.oemCode,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        this.$Message.destroy()
                        if (res && res.code == 200) {
                            if (
                                List &&
                                List.length &&
                                this.sendGroup.pushChannel &&
                                this.sendGroup.pushChannel == "3" &&
                                this.caozuoInfo.functionType != "24" &&
                                this.caozuoInfo.functionType != "25" &&
                                this.caozuoInfo.functionType != "15" &&
                                this.caozuoInfo.functionType != "5" &&
                                this.caozuoInfo.functionType != "17"
                            ) {
                                List.map(async item => {
                                    console.log({
                                        businessCode: 2,
                                        businessType: 1,
                                        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                        extra: JSON.stringify({
                                            title: this.caozuoInfo.title,
                                            image: this.caozuoInfo.photo || "",
                                            note: this.$core.getTextFromRichText(this.caozuoInfo.content),
                                            source: parent.vue.loginInfo.userinfo.orgName,
                                        }),
                                        gmtCreate: new Date().getTime(),
                                        groupId: item,
                                        msg: proxy["/custom"].target + "/#/apps/suiyue/pages/information/sharingdetails/details?id=" + this.caozuoInfo.id + "&isAppLocal=true" + `&functionType=${this.caozuoInfo.functionType}`,
                                        msgLength: null,
                                        msgType: 5,
                                        oemCode: parent.vue.oemInfo.oemCode,
                                    })
                                    await this.$post("/sendmsg/app/chat/group/transmitMessage", {
                                        message: encrypt(
                                            JSON.stringify({
                                                businessCode: 2,
                                                businessType: 1,
                                                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                extra: JSON.stringify({
                                                    title: this.caozuoInfo.title,
                                                    image: this.caozuoInfo.photo || "",
                                                    note: this.$core.getTextFromRichText(this.caozuoInfo.content),
                                                    source: parent.vue.loginInfo.userinfo.orgName,
                                                }),
                                                gmtCreate: new Date().getTime(),
                                                groupId: item,
                                                msg: proxy["/custom"].target + "/#/apps/suiyue/pages/information/sharingdetails/details?id=" + this.caozuoInfo.id + "&isAppLocal=true" + `&functionType=${this.caozuoInfo.functionType}`,
                                                msgLength: null,
                                                msgType: 5,
                                                oemCode: parent.vue.oemInfo.oemCode,
                                            })
                                        ),
                                    })
                                })
                            }
                            this.changeGroupStatus2(false)
                            this.$Message.success({
                                content: "操作成功",
                                background: true,
                            })
                            return
                        } else {
                            this.$Message.error({
                                content: "操作失败",
                                background: true,
                            })
                            return
                        }
                    })
                },
            })
        },
        // 精准推送
        changeOpenSendModal() {
            if (this.caozuoInfo.status != "4") {
                this.$Message.warning({
                    content: "已发布的内容才可精准推送给用户",
                    background: true,
                })
                return
            }
            this.sendGroup.pushChannel = ""
            this.groupSelect = []
            this.selectList = []
            this.rightList = []
            this.removeRightList = []
            this.grouppage = 1
            this.grouptotal = 0
            this.grouploading = false
            this.grouppageSize = 20
            this.grouptableData = []
            this.changeGroupStatus2(true)
            this.getGroupList()
        },
        // 推送记录
        jumpSendDetail() {
            this.$core.openLayerFrame({
                type: 2,
                title: "推送记录",
                content: `/page#/pushTable?informationId=${this.caozuoInfo.id}&title=${this.caozuoInfo.title}&code=${this.caozuoInfo.code}`,
                area: ["100%", "100%"],
            })
        },
        // 获取群分类
        async getSelect() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                let data = res.dataList
                this.groupList = []
                data.map(item => {
                    // if (item.groupType != "1") {
                    this.groupList.push({
                        value: item.dictKey,
                        label: item.dictValue,
                    })
                    // }
                })
            })
            // 我的管理员
            this.$.ajax({
                url: proxy["/gateway"].target + "/apps/oldpeople/api/pc/staff/getStaffCount",
                type: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                },
                success: res => {
                    if (res && res.data && res.data) this.coreData.custNum = res.data
                },
            })
            // 我的用户数量
            this.$get("/gateway/sy-user/sy-user/pc/syuser/selectByOrgAnalyze", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res && res.data && res.data.total) {
                    this.coreData.userNum = res.data.total
                }
            })
            // 社区用户注册用户
            try {
                this.$get("/statistic/api/symanage/pc/homepage/statHomePageInfo", {
                    orgCode: JSON.parse(sessionStorage.getItem("userDetail")).communityCode || "",
                }).then(res => {
                    if (res.code == 200) {
                        if (res.data.userRegister) {
                            this.coreData.registerNum = res.data.userRegister.total || 0
                        }
                        if (res.data.statRegister) {
                            this.coreData.viewNum = res.data.statRegister.total || 0
                        }
                    }
                })
            } catch (err) {}
        },
        // 获取群列表
        async getGroupList() {
            await this.getSelect()
            await this.$get("/sendmsg/pc/chat/groupCreate/queryPage", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                groupType: "",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                pageSize: this.grouppageSize,
                page: this.grouppage,
            })
                .then(res => {
                    this.grouptalbeColumns = [
                        {
                            type: "selection",
                            width: 60,
                            align: "center",
                        },
                        {
                            title: "群名称",
                            key: "groupName",
                            width: 170,
                            align: "center",
                        },
                        {
                            title: "群成员数",
                            key: "memberCount",
                            width: 170,
                            align: "center",
                        },
                        {
                            title: "群类型",
                            key: "groupTypeName",
                            align: "center",
                            width: 170,
                        },
                    ]
                    if (res.code === "200") {
                        res.dataList.map((item, index, arr) => {
                            arr[index].gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm:ss")
                            this.groupList.map(items => {
                                if (item.groupType == items.value) {
                                    item.groupTypeName = items.label
                                }
                            })
                            item.scopeName = item.scopeName.replace(/\//g, "")
                            let list = []
                            item.chatGroupManager.map(item => {
                                if (item.manager != null) {
                                    list.push(item.manager)
                                }
                            })
                            item.chatGroupManager = list.join(",")
                        })
                        this.grouptableData = res.dataList
                        this.grouptotal = res.maxCount
                        this.grouppageSize = res.pageSize
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "数据获取失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async findReportByOrgCode() {
            await this.$get("/gateway/sy-user/sy-user/api/syuser/pc/userBaseStat/findReportByOrgCode", {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
            }).then(res => {
                this.grouptalbeColumns = [
                    {
                        type: "selection",
                        width: 60,
                        align: "center",
                    },
                    {
                        title: "报表名称",
                        key: "reportName",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表总人数",
                        key: "userTotal",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表激活人数",
                        key: "activeUser",
                        width: 170,
                        align: "center",
                    },
                ]
                if (res.code === "200") {
                    this.grouptableData = res.dataList.map(item => {
                        return {
                            ...item,
                            groupId: item.appId,
                            groupName: item.reportName,
                        }
                    })
                    this.grouptotal = res.maxCount
                    this.grouppageSize = res.pageSize
                } else {
                    this.$Message.error({
                        background: true,
                        content: "数据获取失败",
                    })
                }
            })
        },
        async selectAppByOrgCode() {
            await this.$get("/gateway/api/fastDev/AppForm/selectAppByOrgCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                pageSize: this.grouppageSize,
                page: this.grouppage,
                busiCategory:'DYTX'
            }).then(res => {
                this.grouptalbeColumns = [
                    {
                        type: "selection",
                        width: 60,
                        align: "center",
                    },
                    {
                        title: "报表名称",
                        key: "appName",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表总人数",
                        key: "dataAmount",
                        width: 170,
                        align: "center",
                    },
                    {
                        title: "报表激活人数",
                        key: "activeUserAmount",
                        width: 170,
                        align: "center",
                    },
                ]
                if (res.code === "200") {
                    this.grouptableData = res.dataList.map(item => {
                        return {
                            ...item,
                            ...item.appDataCountVo,
                            groupId: item.appId,
                            groupName: item.appName,
                        }
                    })
                    this.grouptotal = res.maxCount
                    this.grouppageSize = res.pageSize
                } else {
                    this.$Message.error({
                        background: true,
                        content: "数据获取失败",
                    })
                }
            })
        },

        async changePushChannel(val) {
            this.sendGroup.pushChannel = val
            this.groupSelect = []
            this.selectList = []
            this.rightList = []
            this.removeRightList = []
            this.grouppage = 1
            this.grouptotal = 0
            this.grouploading = false
            this.grouppageSize = 20
            this.grouptableData = []
            if (val && val == 3) {
                console.log("如果等于3")
                await this.getGroupList()
                return
            } else if (val && val == 7) {
                console.log("如果等于7")
                await this.findReportByOrgCode()
                return
            }else if(val && val == 10){
                console.log('如果等于10');
                await this.selectAppByOrgCode()
            }
        },
        getContent() {
            try {
                this.setContent = {
                    terminal: (() => {
                        if (this.caozuoInfo.terminal && this.caozuoInfo.terminal != "") {
                            if (this.caozuoInfo.terminal.indexOf("0") > -1) {
                                console.log("如果是全选")
                                this.channelList.map(item => {
                                    item.select = true
                                })
                            } else {
                                this.channelList.map(item => {
                                    if (this.caozuoInfo.terminal.includes(item.label)) item.select = true
                                    else item.select = false
                                })
                                console.log("如果只是选择了几个", this.channelList)
                            }
                        }
                        return this.caozuoInfo.terminal
                    })(),
                    recommend: this.caozuoInfo.recommend && this.caozuoInfo.recommend ? this.caozuoInfo.recommend : "",
                    contactMobile: this.caozuoInfo.contactMobile && this.caozuoInfo.contactMobile != "" ? this.caozuoInfo.contactMobile : "",
                    pushType:
                        this.caozuoInfo.pushChannelVo && this.caozuoInfo.pushChannelVo.pushType && this.caozuoInfo.pushChannelVo.pushType != ""
                            ? this.caozuoInfo.pushChannelVo.pushType.split(",")
                            : [],
                    groupNames: (() => {
                        if (this.caozuoInfo.groupList && this.caozuoInfo.groupList.length != 0) {
                            let arr = []
                            this.caozuoInfo.groupList.map(item => {
                                arr.push(item.groupName)
                            })
                            this.$refs.checkGroup.rightList = this.caozuoInfo.groupList
                            return arr.join(",")
                        } else {
                            return ""
                        }
                    })(),
                    channels: this.caozuoInfo.pushChannelVo && this.caozuoInfo.pushChannelVo.channels ? this.caozuoInfo.pushChannelVo.channels : "",
                }
                this.imageDesc = ''
                this.defaultListIcon = []
                if (this.caozuoInfo.ruleVos && this.caozuoInfo.ruleVos.length) {
                    this.caozuoInfo.ruleVos.map(item => {
                        if (item.ruleCode == "SUCCESS_IMAGE") {
                            if (item.ruleValue && item.ruleValue != "") this.defaultListIcon = [{ name: "", url: item.ruleValue }]
                        }
                        if (item.ruleCode.indexOf("SUCCESS_DESC") > -1) {
                            this.imageDesc = item.ruleValue
                        }
                    })

                }
            } catch (err) {
                console.log("err", err)
                this.setContent = {
                    terminal: [],
                    recommend: "",
                    contactMobile: "",
                    pushType: [],
                    groupNames: "",
                    channels: "",
                    tipsCodeList: [],
                }
                this.channelList = [
                    { label: "0", value: "全部", select: "" },
                    { label: "1", value: "TV端", select: "" },
                    { label: "3", value: "APP端", select: "" },
                    { label: "4", value: "小程序", select: "" },
                ]
            }
        },
        async setSave() {
            let rules = this.caozuoInfo.ruleVos || []
            if (rules && rules.length) {
                if (this.$refs.LiefengUploadIcon && this.$refs.LiefengUploadIcon.uploadList && this.$refs.LiefengUploadIcon.uploadList.length && this.$refs.LiefengUploadIcon.uploadList[0] && this.$refs.LiefengUploadIcon.uploadList[0].url != "") {
                    let isFlag = false
                    rules.map(item => {
                        if (item.ruleCode == "SUCCESS_IMAGE") {
                            isFlag = true
                            item.ruleValue = this.$refs.LiefengUploadIcon.uploadList[0].url
                        }
                    })
                    if (!isFlag) {
                        rules.push({
                            ruleCode: "SUCCESS_IMAGE",
                            ruleValue: this.$refs.LiefengUploadIcon.uploadList[0].url,
                        })
                    }
                } else {
                    let isFlag = false
                    rules.map(item => {
                        if (item.ruleCode == "SUCCESS_IMAGE") {
                            isFlag = true
                            item.ruleValue = ""
                        }
                    })
                    if (!isFlag) {
                        rules.push({
                            ruleCode: "SUCCESS_IMAGE",
                            ruleValue: "",
                        })
                    }
                }
                if (this.imageDesc && this.imageDesc != "") {
                    let isFlag = false
                    rules.map(item => {
                        if (item.ruleCode == "SUCCESS_DESC") {
                            isFlag = true
                            item.ruleValue = this.imageDesc
                        }
                    })
                    if (!isFlag) {
                        rules.push({
                            ruleCode: "SUCCESS_DESC",
                            ruleValue: this.imageDesc,
                        })
                    }
                } else {
                    let isFlag = false
                    rules.map(item => {
                        if (item.ruleCode == "SUCCESS_DESC") {
                            isFlag = true
                            item.ruleValue = ""
                        }
                    })
                    if (!isFlag) {
                        rules.push({
                            ruleCode: "SUCCESS_DESC",
                            ruleValue: "",
                        })
                    }
                }
            }

            this.$Message.loading({
                content: "正在保存，请稍等",
            })
            await this.changeRow({
                ...this.setContent,
                pushType: this.setContent.pushType && this.setContent.pushType.length ? this.setContent.pushType.join(",") : "",
                id: this.caozuoInfo.id,
                sort: this.caozuoInfo.sort,
                status: this.caozuoInfo.status,
                terminal: this.setContent.terminal.indexOf("0") != -1 ? "0" : typeof this.setContent.terminal == "Array" ? this.setContent.terminal.join(",") : this.setContent.terminal,
                terminalField: this.setContent.terminal.indexOf("0") != -1 ? "0" : typeof this.setContent.terminal == "Array" ? this.setContent.terminal.join(",") : this.setContent.terminal,
                functionType: this.caozuoInfo.functionType,
                businessType: this.caozuoInfo.businessType,
                ruleVos: rules,
            })
            this.$Message.destroy()
            this.caozuoStatusFn(false)
        },
        changeGroupStatus(val) {
            this.groupStatus = val
        },

        saveGroup() {
            let list = this.$refs.checkGroup.rightList
            if (list.length == 0) {
                this.$Message.warning({
                    content: "请选择群",
                    background: true,
                })
                return
            }
            let groupName = []
            let groupId = []
            list.map(item => {
                groupName.push(item.groupName)
                groupId.push(item.groupId)
            })
            this.setContent.groupNames = groupName.join(",")
            this.setContent.channels = groupId.join(",")
            this.groupStatus = false
        },
        changePushType(data) {
            if (data && data.length) {
                this.setContent.pushType = []
                this.setContent.pushType.push(data[data.length - 1])
            }
        },
        // 点击推送方式的checkbox按钮
        changeCheck(val, item) {
            if (item.label == "0") {
                if (val) {
                    this.channelList.map(item => {
                        item.select = true
                    })
                } else {
                    this.channelList.map(item => {
                        item.select = false
                    })
                }
            } else {
                if (val) {
                    let data = []
                    this.channelList.map(item => {
                        if (item.label != "0") {
                            if (item.select) {
                                data.push(item)
                            }
                        }
                    })
                    if (data.length == this.channelList.length - 1) {
                        this.channelList.map(item => {
                            item.select = true
                        })
                    }
                } else {
                    this.channelList.map(item => {
                        if (item.label == "0") {
                            item.select = false
                        }
                    })
                }
            }

            let data = []
            this.channelList.map(item => {
                if (item.label == "0") {
                    this.setContent.terminal = item.label
                } else {
                    if (item.select == true) {
                        data.push(item.label)
                    }
                }
            })
            if (data.length != 0) {
                if (data.length == this.channelList.length - 1) {
                    this.setContent.terminal = "0"
                } else {
                    this.setContent.terminal = data.join(",")
                }
            } else {
                this.setContent.terminal = ""
            }
            console.log(this.setContent.terminal)
        },
        // 操作按钮
        changeMore(id) {
            this.$core.openLayerFrame({
                type: 2,
                title: "微信群阅读情况",
                content: `/page#/wechatgroupchart?id=${id}`,
                area: ["100%", "100%"],
            })
        },
        changeOtherItem(item) {
            let params = {
                row: this.caozuoInfo,
            }
            if (item) {
                let obj = {}
                this.caozuoStatusFn(false)
                switch (item.type) {
                    // 归档
                    case "1":
                        this.titleName = "归档"
                        this.affairsType = "file"
                        this.getDetail(params.row.id)
                        return
                    // 过期
                    case "2":
                        this.titleName = "过期"
                        this.affairsType = "old"
                        this.getDetailSound(params.row.id)
                        return
                    // 复制
                    case "3":
                        this.titleName = "新增"
                        this.affairsType = "quote"
                        this.getDetail(params.row.id)
                        return
                    // 删除
                    case "4":
                        this.$Modal.confirm({
                            title: "温馨提示",
                            content: "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                            onOk: () => {
                                this.deleteNews(params.row.id)
                            },
                        })
                        return
                    // 更换栏目
                    case "5":
                        this.synchroniZationStatus = true
                        this.synchronId = params.row.id
                        return
                    // 设为模板
                    case "6":
                        this.setTemplate(params.row)
                        return
                    // 关联数据
                    case "7":
                        this.quesDatef = params.row
                        this.quesDatef.businessType = this.menuCodeObj.businessType
                        this.quesDatef.functionType = this.menuCodeObj.functionType
                        this.relationStatus = true
                        this.showRNum++
                        return
                    // 配置相关链接
                    case "8":
                        this.infoCode = params.row.code
                        this.aboutInfo = params.row.id
                        this.aboutLinkStatus = true
                        return
                    // 拟制机构推送
                    case "9":
                        this.pushObj.informationId = params.row.id
                        this.pushObj.orgCode = params.row.orgCode
                        this.pushObj.functionType = params.row.functionType
                        this.pushObj.orgName = params.row.source
                        this.pushObj.title = params.row.title.substring(0, 20)
                        this.pushStatus = true
                        return
                    // 用户操作记录
                    case "10":
                        this.sendMessage = {
                            businessType: params.row.businessType,
                            infoId: params.row.id,
                        }
                        this.isOpenUserMessage = true
                        this.modelTitle = params.row.title
                        this.userChooseTle = true
                        return
                    // 答题记录
                    case "11":
                        this.$Message.loading({
                            background: true,
                            content: "正在加载，请稍等...",
                        })
                        this.$get("/info/api/pc/information/v2/queryById", {
                            infoId: params.row.id,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: 2,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "答题记录",
                                    content: `/page#/answerrecord?menuId=${this.$route.query.menuId}&id=${params.row.id}&type=${res.data.answer.ruleVo.module}`,
                                    area: ["100%", "100%"],
                                })
                            } else {
                                this.$Message.error({
                                    content: "获取答题记录失败",
                                    background: true,
                                })
                                return
                            }
                        })
                        return
                    // 中奖名单
                    case "12":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "中奖名单",
                            content: `/page#/answerwinning?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 抽奖名单
                    case "13":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "抽奖名单",
                            content: `/page#/luckdrawindex?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 排行榜
                    case "14":
                        this.$Message.loading({
                            background: true,
                            content: "正在加载，请稍等...",
                        })
                        this.$get("/info/api/pc/information/v2/queryById", {
                            infoId: params.row.id,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: 2,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "排行榜",
                                    content: `/page#/answerrang?menuId=${this.$route.query.menuId}&id=${params.row.id}&type=${res.data.answer.ruleVo.module}`,
                                    area: ["100%", "100%"],
                                })
                            } else {
                                this.$Message.error({
                                    content: "获取排行版失败",
                                    background: true,
                                })
                                return
                            }
                        })
                        return
                    // 采集信息
                    case "15":
                        return
                    // 通知记录
                    case "16":
                        return
                    // 对比分析
                    case "17":
                        return
                    // 报名时段管理
                    case "18":
                        return
                    // 报名记录
                    case "19":
                        return
                    // 签到码
                    case "20":
                        return
                    // 排号记录
                    case "21":
                        return
                    // 畅联组应读已读记录
                    case "22":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "畅联组应读已读记录",
                            content: `/page#/statisticsread?i=${params.row.id}&menuId=${this.$route.query.menuId}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 更新公布时间
                    case "23":
                        let paramss = {
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            id: params.row.id, //当前信息类id
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType || this.menuCodeObj.functionType || "",
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            sort: params.row.sort,
                            recommend: params.row.recommend,
                            pushChannel: params.row.pushChannel || "",
                            status: params.row.status,
                        }

                        this.$post("/info/api/pc/information/v2/flushInformationTime", paramss)
                            .then(res => {
                                if (res.code == 200) {
                                    this.$Message.success({
                                        background: true,
                                        content: "更新时间成功",
                                    })
                                    return
                                } else {
                                    this.$Message.error({
                                        background: true,
                                        content: "更新时间失败",
                                    })
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        return

                    // 指定人群应读已读记录
                    case "24":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "指定人群应读已读记录",
                            content: `/page#/statisticsread?i=${params.row.id}&menuId=${this.$route.query.menuId}&type=1`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 抽奖二维码
                    case "25":
                        this.onDisplayQrcode(params.row.id)
                        return
                }
            }
        },
        changeItem(item) {
            let params = {
                row: this.caozuoInfo,
            }
            console.log("操作列", this.caozuoInfo)
            if (item) {
                let obj = {}
                this.caozuoStatusFn(false)
                switch (item.type) {
                    // 归档 // 过期 // 复制
                    case "1":
                    case "2":
                        this.shenheChange(params.row.status, params.row.functionType, params.row.columnCode, params.row.id, params.row.orgCode)
                        return
                    // 复制
                    case "3":
                        this.copyChange(params)
                        return
                    // 删除
                    case "4":
                        this.$Modal.confirm({
                            title: "温馨提示",
                            content: "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                            onOk: () => {
                                this.deleteRow(params.row.id, params.row.businessType, params.row.functionType)
                            },
                        })
                        return
                    // 更换栏目
                    case "5":
                        this.synchroniZationStatus = true
                        this.synchronId = params.row.id
                        this.functionType = params.row.functionType
                        return
                    // 设为模板
                    case "6":
                        this.setTemplate(params.row)
                        return
                    // 关联数据
                    case "7":
                        this.quesDatef = params.row
                        this.quesDatef.businessType = this.menuCodeObj.businessType
                        this.quesDatef.functionType = this.menuCodeObj.functionType
                        this.relationStatus = true
                        this.showRNum++
                        return
                    // 配置相关链接
                    case "8":
                        this.infoCode = params.row.code
                        this.aboutInfo = params.row.id
                        this.aboutLinkStatus = true
                        return
                    // 拟制机构推送
                    case "9":
                        this.pushObj.informationId = params.row.id
                        this.pushObj.orgCode = params.row.orgCode
                        this.pushObj.functionType = params.row.functionType
                        this.pushObj.orgName = params.row.source
                        this.pushObj.title = params.row.title.substring(0, 20)
                        this.pushStatus = true
                        return
                    // 用户操作记录
                    case "10":
                        this.sendMessage = {
                            businessType: params.row.businessType,
                            infoId: params.row.id,
                        }
                        this.isOpenUserMessage = true
                        this.modelTitle = params.row.title
                        this.userChooseTle = true
                        return
                    // 答题记录
                    case "11":
                        this.$Message.loading({
                            background: true,
                            content: "正在加载，请稍等...",
                        })
                        this.$get("/info/api/pc/information/v2/queryById", {
                            infoId: params.row.id,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: 2,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "答题记录",
                                    content: `/page#/answerrecord?menuId=${this.$route.query.menuId}&id=${params.row.id}&type=${res.data.answer.ruleVo.module}`,
                                    area: ["100%", "100%"],
                                })
                            } else {
                                this.$Message.error({
                                    content: "获取答题记录失败",
                                    background: true,
                                })
                                return
                            }
                        })
                        return
                    // 中奖名单
                    case "12":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "中奖名单",
                            content: `/page#/answerwinning?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 抽奖名单
                    case "13":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "抽奖名单",
                            content: `/page#/luckdrawindex?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 排行榜
                    case "14":
                        this.$Message.loading({
                            background: true,
                            content: "正在加载，请稍等...",
                        })
                        this.$get("/info/api/pc/information/v2/queryById", {
                            infoId: params.row.id,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: 2,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "排行榜",
                                    content: `/page#/answerrang?menuId=${this.$route.query.menuId}&id=${params.row.id}&type=${res.data.answer.ruleVo.module}`,
                                    area: ["100%", "100%"],
                                })
                            } else {
                                this.$Message.error({
                                    content: "获取排行版失败",
                                    background: true,
                                })
                                return
                            }
                        })
                        return
                    // 采集信息
                    case "15":
                        if (this.caozuoInfo.functionType == 24) {
                            this.$core.openLayerFrame({
                                type: 2,
                                title: "采集信息",
                                content: `/page#/activitycollect?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                                area: ["100%", "100%"],
                            })
                            return
                        } else if (this.caozuoInfo.functionType == 25) {
                            this.$core.openLayerFrame({
                                type: 2,
                                title: "采集信息",
                                content: `/page#/questionnairecollect?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                                area: ["100%", "100%"],
                            })
                            return
                        }

                    // 通知记录
                    case "16":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "通知记录",
                            content: `/page#/notificationrecord?menuId=${this.$route.query.menuId}&id=${params.row.id}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 对比分析
                    case "17":
                        this.contrastStatusFenxi = true
                        this.contrastShow = true
                        this.contrastInfoId = params.row.id
                        this.conBusinessType = params.row.businessType
                        this.conFunctionType = params.row.functionType
                        return
                    // 报名时段管理
                    case "18":
                        if (params.row.status == 5) {
                            // 信息归档后，控制操作
                            obj.disabled = true
                        }
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "报名时段管理",
                            content: `/page#/activityinterval?id=${params.row.id}&menuCode=${this.$route.query.menuId}&endDate=${params.row.endDate}&disabled=${obj.disabled || false}`,
                            area: ["100%", "100%"],
                        })
                        return

                    // 报名记录
                    case "19":
                        if (params.row.status == 5) {
                            // 信息归档后，控制操作
                            obj.disabled = true
                        }

                        this.$get("/info/api/pc/information/v2/queryById", {
                            infoId: params.row.id,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: 2,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                let dataScopeList = []
                                if (res.data.dataScopeList && res.data.dataScopeList.length) {
                                    res.data.dataScopeList.map(item => {
                                        dataScopeList.push(item.split("-"))
                                    })
                                }
                                this.$Message.loading({
                                    background: true,
                                    content: "正在加载，请稍等...",
                                })
                                
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "报名记录",
                                    content: `/page#/activityrecord?dataScopeList=${encodeURIComponent(JSON.stringify(dataScopeList))}&formId=${params.row.id}&id=${params.row.id}&functionType=${params.row.functionType}&menuCode=${this.$core.getUrlParam(
                                        "menuId"
                                    )}&period=${(() => {
                                        let result = ""
                                        res.data.ruleVos.map(item => {
                                            if (item.ruleCode == "whether_period") {
                                                result = item.ruleValue
                                            }
                                        })
                                        return result
                                    })() || ""}&nameType=${(() => {
                                        let result = ""
                                        res.data.ruleVos.map(item => {
                                            if (item.ruleCode == "authentication") {
                                                result = item.ruleValue === "" ? "none" : item.ruleValue
                                            }
                                        })
                                        return result
                                    })() || ""}&disabled=${obj.disabled || ""}&whetherSign=${params.row.appointmentVo.whetherSign}`,
                                    area: ["100%", "100%"],
                                })
                                return
                            } else {
                                this.$Message.error({
                                    content: "获取报名记录失败",
                                    background: true,
                                })
                                return
                            }
                        })
                        return

                    // 签到码
                    case "20":
                        this.$Message.loading({
                            background: true,
                            content: "正在加载数据，请稍等...",
                            duration: 0,
                        })
                        this.signInObj = {
                            id: params.row.id,
                            businessType: params.row.businessType,
                            title: params.row.title,
                        }
                        this.signInOutStatus = true
                        this.$get("/old/api/pc/information/getInformationScopeLevel", {
                            informId: params.row.id,
                        })
                            .then(res => {
                                if (res.code == 200) {
                                    this.scopeStatus = res.data
                                    this.getScopeList(params.row.id, "3")
                                } else {
                                    this.$Message.destroy()
                                    this.$Message.error({
                                        background: true,
                                        content: res.desc,
                                    })
                                }
                            })
                            .catch(err => {
                                this.$Message.destroy()
                                this.$Message.error({
                                    background: true,
                                    content: "获取数据失败，请联系管理员处理",
                                })
                            })
                        return
                    // 排号记录
                    case "21":
                        if (params.row.status == 5) {
                            // 信息归档后，控制操作
                            obj.disabled = true
                        }
                        this.$Message.loading({
                            background: true,
                            content: "正在加载，请稍等...",
                        })
                        this.$get("/info/api/pc/information/v2/queryById", {
                            infoId: params.row.id,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: 2,
                        }).then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "排号记录",
                                    content: `/page#/record?id=${params.row.id}&functionType=${params.row.functionType}&menuCode=${this.$core.getUrlParam("menuId")}&period=${(() => {
                                        let result = ""
                                        res.data.ruleVos.map(item => {
                                            if (item.ruleCode == "whether_period") {
                                                result = item.ruleValue
                                            }
                                        })
                                        return result
                                    })() || ""}&disabled=${obj.disabled || ""}&whetherSign=${params.row.appointmentVo.whetherSign}`,
                                    area: ["100%", "100%"],
                                })
                                return
                            } else {
                                this.$Message.error({
                                    content: "获取排号记录失败",
                                    background: true,
                                })
                                return
                            }
                        })
                        return
                    // 已读未读
                    case "22":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "畅联组应读已读记录",
                            content: `/page#/statisticsread?i=${params.row.id}&menuId=${this.$route.query.menuId}`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 更新时间
                    case "23":
                        let paramss = {
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            id: params.row.id, //当前信息类id
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            businessType: params.row.businessType,
                            functionType: params.row.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            sort: params.row.sort,
                            recommend: params.row.recommend,
                            pushChannel: params.row.pushChannel,
                            status: params.row.status,
                        }

                        this.$post("/info/api/pc/information/v2/flushInformationTime", paramss)
                            .then(res => {
                                if (res.code == 200) {
                                    this.$Message.success({
                                        background: true,
                                        content: "更新时间成功",
                                    })
                                    return
                                } else {
                                    this.$Message.error({
                                        background: true,
                                        content: "更新时间失败",
                                    })
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        return

                    // 指定人群应读已读记录
                    case "24":
                        this.$core.openLayerFrame({
                            type: 2,
                            title: "指定人群应读已读记录",
                            content: `/page#/statisticsread?i=${params.row.id}&menuId=${this.$route.query.menuId}&type=1`,
                            area: ["100%", "100%"],
                        })
                        return
                    // 抽奖二维码
                    case "25":
                        this.onDisplayQrcode(params.row.id)
                        return
                }
            }
        },
        // 关闭操作模态框
        caozuoStatusFn(status) {
            this.caozuoStatus = status
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
            if (this.caozuoStatus) {
                //获取数据字典数据
                this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                    appCode: "SUIYUE",
                    dictType: "INFORMATION_TIPS",
                }).then(res => {
                    this.typeList = res.dataList
                })
            }
        },
        // 关闭所有的layer iframe弹窗
        closeAllModal() {
            if (this.$route.query.id && this.$route.query.functionType && this.$route.query.columnCode && this.$route.query.status && (this.$route.query.status == 1 || this.$route.query.status == 3 || this.$route.query.status == 4)) {
                parent.layer.closeAll()
            }
        },
        // 点击了查看逻辑
        checkChange() {
            this.titleName = "查看"
            this.affairsType = "check"

            if (this.$route.query.functionType == "3") {
                this.getDetailSound(this.$route.query.id)
            } else {
                this.getDetail(this.$route.query.id)
            }
        },
        // 审核逻辑
        shenheChange() {
            this.getRoot()
            if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status) {
                switch (this.$route.query.status) {
                    case "1":
                        this.titleName = "审核"
                        this.affairsType = "examine"
                        break
                    case "3":
                        this.titleName = "发布"
                        this.affairsType = "send"
                        break
                    case "4":
                        this.titleName = "过期"
                        this.affairsType = "old"
                        break
                    case "8":
                        this.titleName = "归档"
                        this.affairsType = "file"
                }
                this.getDetail(this.$route.query.id)
            }
        },
        // 点击了新增按钮
        addChange() {
            if (this.$route.query.columnCode && this.$route.query.functionType) {
                this.contrastStatus = true
                this.columnCode = this.$route.query.columnCode
                this.titleName = "新增"
                this.affairsType = "add"
                this.menuCodeObj = {
                    columnCode: this.$route.query.columnCode,
                    functionType: this.$route.query.functionType,
                }
                console.log("新增获取的type", this.menuCodeObj)
                this.addShowNum()
                this.showNum = 0
                this.$nextTick(() => {
                    ++this.showNum
                })
                return
            }
            this.templateStatus = true
            this.columnCode = this.menuCodeObj.columnCode
            this.$nextTick(() => {
                this.$refs.Templatetable.getList()
            })
            this.titleName = "新增"
            ++this.showNum
        },
        // 点击了修改按钮
        editChange() {
            this.titleName = "修改"
            this.affairsType = "edit"
            this.columnCode = this.$route.query.columnCode
            this.menuCodeObj = {
                columnCode: this.columnCode,
                functionType: this.$route.query.functionType,
                businessType: "0",
            }
            this.getDetail(this.$route.query.id)
        },
        copyChange() {
            this.titleName = "新增"
            this.affairsType = "quote"
            this.columnCode = this.$route.query.columnCode
            this.menuCodeObj = {
                columnCode: this.columnCode,
                functionType: this.$route.query.functionType,
                businessType: "0",
            }
            this.getDetail(this.$route.query.id)
        },
        // 引用逻辑
        sendChange(val) {
            console.log("val数据", val)
            this.detailMessage = val
            this.formData = val
            this.affairsType = "quote"
            let obj = JSON.parse(JSON.stringify(val))
            if (obj.mapRos && obj.mapRos.length != 0) {
                obj.longitude = obj.mapRos[0].longitude + "," + obj.mapRos[0].latitude
            }
            this.formData = obj
            this.formData.source = parent.vue.loginInfo.userinfo.orgName
            this.contrastStatus = true
            this.changeAffairsType("quote")
            this.showNum = 0
            this.$nextTick(() => {
                ++this.showNum
            })
            this.columnCode = this.$route.query.columnCode
            this.formData.columnCode = this.columnCode
            if (document.getElementById("ueditor") && this.formData.content) {
                document.getElementById("ueditor").contentWindow.setContent(this.formData.content)
            }
        },
        // 关闭弹窗的方法
        contrastFn(status) {
            this.contrastStatus = status
            if (!status && this.$route.query.columnCode && this.$route.query.functionType) {
                var index = parent.layer.getFrameIndex(window.name)
                if (index) {
                    //先得到当前iframe层的索引
                    // parent.layer.close(index) //再执行关闭
                    parent.layer.closeAll()
                } else {
                    this.$router.push(`allIndex?menuId=${this.$route.query.menuId || ""}`)
                }
            }
        },
        // 方法部分
        // 发布范围选择的回调事件
        changeCasader(val) {
            this.searchData.dataScopeList = `44-4401-${val.join("-")}`
        },
        // 搜索按钮
        searchBtn() {
            this.page = 1
            this.getList()
        },
        // 重置按钮
        resetBtn() {
            ++this.resetNum
            this.searchData = {}
            this.getList()
        },
        // 点击了左侧菜单的触发事件
        selectMenu(name) {
            if (name == "7") {
                this.status = ""
            } else {
                this.status = name
            }
            this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 获取用户权限，控制按钮隐藏与显示
        getRoot() {
            let userButtonRoot = parent.vue.userButtonRoot
            this.isManager = userButtonRoot.includes("INFORMATION:TEMPLATE_ADD") ? "1" : "2"
            this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
            // this.buttonRoot = "1003";
            // this.status =
            //     this.buttonRoot == "1001"
            //         ? "2"
            //         : this.buttonRoot == "1002"
            //             ? "1"
            //             : this.buttonRoot == "1003"
            //                 ? "3"
            //                 : "";
            // //默认选中第一项菜单
            // this.activeMenu = this.status;

            this.status = ""
            //默认选中第一项菜单
            this.activeMenu = "7"

            // if(this.buttonRoot && this.buttonRoot != '' && this.buttonRoot == '1003'){
            this.talbeColumns.push({
                title: "信息ID",
                key: "id",
                minWidth: 200,
                align: "center",
            })
            // }
        },
        // 接口部分
        // 获取分页数据
        async getList() {
            this.loading = true
            await this.$get(this.pageUrl, {
                ...this.searchData,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                columnCode: this.menuCodeObj.columnCode,
                status: this.status,
                terminal: 2, //访问终端；0:全部；1:tv；2:pc；3:app；4:小程序；5:公众号
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                this.getCount()
                if (res.code == 200) {
                    this.tableData = []
                    this.$nextTick(() => {
                        this.tableData = res.dataList
                    })
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取数据列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取菜单数据
        async getMenuList() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // 获取总数
        async getCount() {
            await this.$get("/datamsg/api/pc/information/v2/groupStatus", {
                dataScopeList: "",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                type: this.menuCodeObj.columnType,
                columnCode: this.menuCodeObj.columnCode,
            }).then(res => {
                if (res.code == 200) {
                    this.auditNumber = res.data.auditNumber
                    this.rejectNumber = res.data.rejectNumber
                    this.waitNumber = res.data.waitNumber
                }
            })
        },
        async getColumnTree() {
            await this.$post(
                "/datamsg/api/pc/information/getChangeColumnMenuTree",
                {
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: this.changeColumnRow.orgCode,
                    staffId: parent.vue.loginInfo.userinfo.id,
                    adminType: parent.vue.loginInfo.userinfo.adminType,
                    businessType: this.changeColumnRow.businessType,
                    infoId: this.changeColumnRow.id,
                }
                // { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    let data = res.dataList
                    this.cascaderData = this.forMatterTree(data)
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        //  更换栏目接口
        async changeColumnRowUpload(menuId) {
            await this.$post("/datamsg/api/pc/information/changeColumn", {
                businessType: this.changeColumnRow.businessType,
                infoId: this.changeColumnRow.id,
                adminType: parent.vue.loginInfo.userinfo.adminType,
                menuId,
            }).then(res => {
                if (res.code == 200) {
                    this.cancelModelTip = false
                    this.ChangeColumnFn(false)
                    this.hadlePageSize({
                        page: this.page,
                        pageSize: this.pageSize,
                    })
                    this.$Message.success({
                        content: "保存成功！",
                        background: true,
                    })
                    this.cascadValue = []
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        // 格式化树结构，给树结构添加上value值和label值
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].title
                data[i].value = data[i].id
                if (data[i].children.length > 0 || !data[i].children) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        // 接口部分
        // 新增接口
        async createRow() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/message/create",
                {
                    ...this.formData,
                    columnCode: this.columnCode,
                    status: "1",
                    terminal: this.formData.terminal,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    recommend: "0",
                    sort: "100",
                    operator: {
                        ...this.formData.operator,
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                        businessType: this.menuCodeObj.businessType || "0",
                    },
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.templateStatus = false
                    this.contrastFn(false)
                    if (this.$route.query.columnCode && this.$route.query.functionType) return
                    this.page = 1
                    this.getList()
                    //    this.getCount()
                } else {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取详情接口
        async getDetail(infoId) {
            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId,
                businessType: this.menuCodeObj.businessType || "0",
                functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                terminal: 2,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    this.detailMessage = res.data
                    this.formData = res.data

                    let obj = JSON.parse(JSON.stringify(res.data))
                    if (obj.mapRos && obj.mapRos.length != 0) {
                        obj.longitude = obj.mapRos[0].longitude + "," + obj.mapRos[0].latitude
                    }
                    if (this.affairsType == "edit" || this.affairsType == "check" || this.affairsType == "examine" || this.affairsType == "file" || this.affairsType == "old" || this.affairsType == "send") {
                        if (obj.pushChannelVo && obj.pushChannelVo.pushType) {
                            obj.pushType = []
                            obj.pushType[0] = obj.pushChannelVo.pushType
                            obj.channels = obj.pushChannelVo.channels
                        }
                        if (obj.groupList && obj.groupList.length != 0) {
                            let arr = []
                            let ids = []
                            obj.groupList.map(item => {
                                arr.push(item.groupName)
                            })
                            obj.groupNames = arr.join(",")
                        }
                    }

                    this.formData = obj

                    this.contrastStatus = true
                    this.showNum++
                    this.columnCode = this.menuCodeObj.columnCode
                    document.getElementById("ueditor").contentWindow.setContent(this.formData.content)
                } else {
                    this.$Message.error({
                        content: "获取用户详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 删除接口
        async deleteRow(infoId, businessType, functionType) {
            await this.$post("/info/api/pc/information/v2/deleteById", {
                infoId,
                businessType: businessType || this.menuCodeObj.businessType,
                functionType: functionType || this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "删除成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.getList()
                    //   this.getCount()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 更新接口
        async updateRow() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/message/update",
                {
                    ...this.formData,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改数据成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.contrastFn(false)
                    if (this.$route.query.columnCode && this.$route.query.functionType) return
                    this.getList()
                    //   this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 改变状态接口
        async changeRow(data) {
            await this.$post(
                "/info/api/pc/information/v2/changeFieldJson",
                {
                    businessType: this.menuCodeObj.businessType || "0",
                    functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                    oemCode: parent.vue.oemInfo.oemCode,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    staffName: parent.vue.loginInfo.userinfo.userName,
                    terminalField: data.terminal,
                    ...data,
                },
                { "Content-type": "application/json" }
            ).then(async res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    await this.getList()
                    //   this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改失败",
                        background: true,
                    })
                    return
                }
            })
        },

        async changeStatusRow(data) {
            await this.$post("/info/api/pc/information/v2/changeIndexJson", {
                ...data,
            },{ "Content-type": "application/json" }).then(async res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    await this.getList()
                } else {
                    this.$Message.error({
                        content: "修改失败",
                        background: true,
                    })
                    return
                }
            })
        },

        // 审核接口
        async changeExan(data) {
            this.passDisable = true
            await this.$post("/info/api/pc/information/v2/change", {
                businessType: this.menuCodeObj.businessType || "0",
                functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                staffName: parent.vue.loginInfo.userinfo.userName,
                ...data,
            }).then(res => {
                this.$Modal.remove()
                this.passDisable = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.rejectStatus = false
                    this.reason = ""
                    this.contrastStatus = false
                    this.closeAllModal()
                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                    return
                }
            })
        },

        // 获取同步栏目接口
        async getChangeMenuCodeTree(infoId) {
            await this.$get("/oldpeople/api/sys/platformMenu/getChangeMenuCodeTree.ac", {
                businessType: this.menuCodeObj.businessType,
                infoId,
            }).then(res => {
                if (res) {
                    return res
                } else {
                    this.$Message.error({
                        content: "获取栏目数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 设置同步栏目接口
        async syncAfficheColumn(id, businessType, menuParamIds) {
            return await this.$post("/oldpeople/api/sys/platformMenu/syncAfficheColumn.ac", {
                id,
                businessType,
                menuParamIds,
            })
        },

        // 字典接口
        async findDictByDictType(dictType) {
            return await this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType,
            })
        },
        // 图文新增
        async createNews() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/news/create",
                {
                    ...this.formData,
                    columnCode: this.menuCodeObj.columnCode || this.$route.query.columnCode,
                    status: "1",
                    terminal: this.formData.terminal,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    recommend: "0",
                    sort: "100",
                    operator: {
                        ...this.formData.operator,
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                    },
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.templateStatus = false
                    this.contrastStatus = false
                    this.page = 1
                    this.getList()
                    // this.getCount()
                } else {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        async updateNews() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/news/update",
                {
                    ...this.formData,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改数据成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false
                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 删除图文接口
        async deleteNews(infoId) {
            await this.$post("/info/api/pc/information/news/deleteRecordById", {
                infoId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "删除成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 地图新增
        async createMap() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/map/create",
                {
                    ...this.formData,
                    columnCode: this.columnCode,
                    status: "1",
                    terminal: this.formData.terminal,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    recommend: "0",
                    sort: "100",
                    operator: {
                        ...this.formData.operator,
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                        businessType: this.menuCodeObj.businessType || "0",
                    },
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.templateStatus = false
                    this.contrastStatus = false
                    this.page = 1
                    this.getList()
                    // this.getCount()
                } else {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        async updateMap() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/map/update",
                {
                    ...this.formData,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改数据成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false
                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        async deleteMap(infoId) {
            await this.$post("/info/api/pc/information/map/deleteRecordById", {
                infoId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "删除成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },

        // 获取模板图片
        async getImgTemplate() {
            await this.$get("/datamsg/api/pc/fileUpload/selectFileUploadPage", {
                groupId: 10000,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                fileType: image,
                page: 1,
                pageSize: 100,
            }).then(res => {
                if (res.code == 200) {
                    return res.data
                }
            })
        },
        // 音视频判断哪一类格式
        uploadJudge(type, value) {
            let state = Boolean
            if (value == "code") {
                state = true
            } else if (value == "name") {
                state = false
            }
            let name = "未知"
            if (
                type.indexOf("avi") > -1 ||
                type.indexOf("rmvb") > -1 ||
                type.indexOf("rm") > -1 ||
                type.indexOf("asf") > -1 ||
                type.indexOf("divx") > -1 ||
                type.indexOf("mpg") > -1 ||
                type.indexOf("mpeg") > -1 ||
                type.indexOf("mpe") > -1 ||
                type.indexOf("wmv") > -1 ||
                type.indexOf("mp4") > -1 ||
                type.indexOf("mkv") > -1 ||
                type.indexOf("vob") > -1
            ) {
                if (state) {
                    name = "video"
                } else {
                    name = "视频"
                }
            } else if (
                type.indexOf("cda") > -1 ||
                type.indexOf("wav") > -1 ||
                type.indexOf("mp3") > -1 ||
                type.indexOf("aif") > -1 ||
                type.indexOf("aiff") > -1 ||
                type.indexOf("mid") > -1 ||
                type.indexOf("wma") > -1 ||
                type.indexOf("ra") > -1 ||
                type.indexOf("vqf") > -1 ||
                type.indexOf("ape") > -1
            ) {
                if (state) {
                    name = "music"
                } else {
                    name = "音频"
                }
            }
            return name
        },
        //   音视频新增接口
        async createSound(arr) {
            console.log("创建时候的type", this.menuCodeObj)
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/audio/create",
                {
                    ...this.formData,
                    columnCode: this.columnCode,
                    status: "1",
                    terminal: this.formData.terminal,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    recommend: "0",
                    sort: "100",
                    audioRos: arr || "",
                    operator: {
                        ...this.formData.operator,
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                        businessType: this.menuCodeObj.businessType || "0",
                    },
                    functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                    businessType: this.menuCodeObj.businessType || "0",
                    subType: this.formData.subType || "",
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.templateStatus = false
                    this.uploadArr = []
                    this.uploadFrom = []
                    this.uploadNamePath = []
                    this.shut_down()
                    this.contrastStatus = false
                    this.page = 1
                    this.getList()
                    // this.getCount()
                } else {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 音视频文件上传oss接口
        async newcreateRow(Type) {
            // this.saveDisabled = true
            let state = true
            for (let i = 0; i < this.uploadFrom.length; i++) {
                if (!this.uploadFrom[i].has("file")) {
                    this.uploadFrom[i].append("file", this.uploadArr[i])
                }
                await this.$post(proxy["/oss"].target, this.uploadFrom[i], { "Content-Type": "multipart/form-data" })
                    .then(res => {})
                    .catch(err => {
                        this.$Message.error({
                            content: `${this.uploadArr[i].name}文件上传失败`,
                            background: true,
                            duration: 2,
                        })
                        this.saveDisabled = false
                        state = false
                    })
            }
            if (state) {
                let param = this.findDictByDictType("INFORMATION_AV_LINK")
                let arr = []
                await param.then(res => {
                    if (Type == "add") {
                        let index = 0
                        let code = ""
                        let name = ""
                        for (let i = 0; i < this.uploadNamePath.length; i++) {
                            index = this.uploadNamePath[i].type.indexOf(".")

                            code = this.uploadJudge(this.uploadNamePath[i].type.substring(index + 1, this.uploadNamePath[i].type.length), "code")
                            name = this.uploadJudge(this.uploadNamePath[i].type.substring(index + 1, this.uploadNamePath[i].type.length), "name")

                            arr.push({ paramValue: this.uploadNamePath[i].url, paramCode: code, paramName: name })
                        }
                        if (this.formData.audioRos) arr = [...this.formData.audioRos, ...arr]
                    } else if (Type == "edit") {
                        let index = 0
                        let code = ""
                        let name = ""
                        for (let i = 0; i < this.uploadNamePath.length; i++) {
                            index = this.uploadNamePath[i].type.indexOf(".")

                            code = this.uploadJudge(this.uploadNamePath[i].type.substring(index + 1, this.uploadNamePath[i].type.length), "code")
                            name = this.uploadJudge(this.uploadNamePath[i].type.substring(index + 1, this.uploadNamePath[i].type.length), "name")

                            this.formData.audioRos.push({ paramValue: this.uploadNamePath[i].url, paramCode: code, paramName: name })
                        }
                    }
                })
                if (Type == "add") {
                    this.createSound(arr)
                } else if (Type == "edit") {
                    this.updateSound()
                }
            }
        },
        // 音视频获取详情接口
        async getDetailSound(infoId) {
            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId,
                businessType: this.menuCodeObj.businessType || "0",
                functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                terminal: 2,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    let obj = JSON.parse(JSON.stringify(res.data))
                    // if(this.affairsType == "edit" || this.affairsType == 'check' || this.affairsType == "examine" || this.affairsType == "file"){
                    if (obj.pushChannelVo && obj.pushChannelVo.pushType) {
                        obj.pushType = []
                        obj.pushType[0] = obj.pushChannelVo.pushType
                    }
                    if (obj.groupList && obj.groupList.length != 0) {
                        let arr = []
                        obj.groupList.map(item => {
                            arr.push(item.groupName)
                        })
                        obj.groupNames = arr.join(",")
                    }
                    //   }

                    this.detailMessage = obj
                    this.formData = obj

                    this.contrastStatus = true
                    this.showNum++
                    this.columnCode = this.menuCodeObj.columnCode
                    document.getElementById("ueditor").contentWindow.setContent(this.formData.content)
                } else {
                    this.$Message.error({
                        content: "获取用户详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 音视频删除接口
        async deleteSound(infoId) {
            await this.$post("/info/api/pc/information/audio/deleteRecordById", {
                infoId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "删除成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 音视频更新接口
        async updateSound() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/audio/update",
                {
                    ...this.formData,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.delete_()
                    this.$Message.success({
                        content: "修改数据成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false

                    this.getList()
                    // this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改数据失败",
                        background: true,
                    })
                    return
                }
            })
        },

        // 设为模板
        async setTemplate(data) {
            await this.$post("/info/api/pc/information/template/addInformationTemplate", {
                infoId: data.id,
                businessType: data.businessType,
                functionType: data.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                terminal: "2",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },

        // 引用跳转
        async indexQuote() {
            if (this.$route.query.templateId) {
                this.$Message.loading({
                    content: "数据正在加载中",
                    duration: 0,
                })
                await this.$get("/info/api/pc/information/template/selectInformationTemplate", {
                    staffId: parent.vue.loginInfo.userinfo.id,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    templateId: this.$route.query.templateId,
                }).then(res => {
                    this.$Message.destroy()
                    if (res.code == 200) {
                        let obj = JSON.parse(res.data.dataJson)
                        this.setFormData(obj)
                        this.contrastFn(true)
                        this.columnCode = this.menuCodeObj.columnCode
                        this.addChangeTitle = "新增"
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
            }
        },
        // 加急
        urgent(status) {
            this.$Modal.confirm({
                title: "加急说明",
                content: "<p>1.经审核提交发布的合格内容，在点击【加急】按钮后，该信息将通过【加急】通道，在15分钟内完成发布流程。</p><p> 2.根据市民政局主管部门的批准意见，【加急】发布的信息，仅限于疫情防控或类似需要紧急发布的信息，具体标准由审核人员参照批准意见加强管控。</p>",
                loading: true,
                onOk: () => {
                    let data = {
                        id: this.detailMessage.id,
                        recommend: this.detailMessage.recommend,
                        sort: this.detailMessage.sort,
                        status,
                        releaseWay: 1,
                        pushType: (this.formData.pushType && this.formData.pushType[0]) || "",
                        channels: this.formData.pushType && this.formData.pushType[0] == 3 ? this.formData.channels : "",
                    }
                    this.changeExan(data)
                },
            })
        },

        async getPushDetail(infoId) {
            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                terminal: 2,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    this.pushObj.informationId = res.data.id
                    this.pushObj.orgCode = res.data.orgCode
                    this.pushObj.functionType = res.data.functionType
                    this.pushObj.orgName = res.data.source
                    this.pushObj.title = res.data.title.substring(0, 20)
                    this.pushStatus = true
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    return
                }
            })
        },

        // 推送
        customPush() {
            if (!this.pushObj.title.trim()) {
                this.$Message.warning({
                    content: "请输入通知内容",
                    background: true,
                })
                return
            }
            this.$post("/gateway/syinfopublish/api/pc/information/v2/customPush", {
                ...this.pushObj,
                publish: true,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "推送成功",
                        background: true,
                    })
                    this.pushStatus = false
                } else {
                    this.$Message.error({
                        content: "推送失败",
                        background: true,
                    })
                }
            })
        },

        // 直播间新增
        // 新增接口
        async createBroadcast() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/broadcast/createBroadcast",
                {
                    ...this.formData,
                    columnCode: this.columnCode,
                    status: "1",
                    terminal: this.formData.terminal,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    recommend: "0",
                    sort: "100",
                    operator: {
                        ...this.formData.operator,
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                        businessType: this.menuCodeObj.businessType || "0",
                    },
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.templateStatus = false
                    this.contrastStatus = false
                    this.page = 1
                    this.getList()
                    //    this.getCount()
                } else {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        // 更新直播间
        async updateBroadcast() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/broadcast/updateBroadcast",
                {
                    ...this.formData,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改数据成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false
                    this.getList()
                    //   this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改数据失败",
                        background: true,
                    })
                    return
                }
            })
        },

        // 答题活动接口
        async createAnswer() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/answer/createAnswer",
                {
                    ...this.formData,
                    columnCode: this.columnCode,
                    status: "1",
                    terminal: this.formData.terminal,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    sort: "100",
                    date: [this.$core.formatDate(new Date(this.formData.date[0]), "yyyy-MM-dd hh:mm:ss"), this.$core.formatDate(new Date(this.formData.date[1]), "yyyy-MM-dd hh:mm:ss")],
                    answer: {
                        businessType: this.menuCodeObj.businessType || "0",
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                        endTime: this.$core.formatDate(new Date(this.$refs.affaris.questDate[1]), "yyyy-MM-dd hh:mm:ss"),
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        questionBankId: this.$refs.affaris.answer.questionBankId,
                        startTime: this.$core.formatDate(new Date(this.$refs.affaris.questDate[0]), "yyyy-MM-dd hh:mm:ss"),
                        status: "1",
                        ruleVo: {
                            ...this.$refs.affaris.ruleVo,
                        },
                        bgColor: this.$refs.affaris.bgColor,
                        bgImg: this.$refs.affaris.$refs.imgData.uploadList && this.$refs.affaris.$refs.imgData.uploadList.length ? this.$refs.affaris.$refs.imgData.uploadList[0].url : "",
                    },
                    ruleVos: this.$refs.affaris.ruleVos,
                    prizeVos: this.$refs.affaris.ruleVo.luckyDraw && this.$refs.affaris.ruleVo.luckyDraw == "1" ? this.$refs.affaris.prizeVos : null,
                    operator: {
                        ...this.formData.operator,
                        functionType: this.menuCodeObj.functionType || this.$route.query.functionType,
                        businessType: this.menuCodeObj.businessType || "0",
                    },
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.templateStatus = false
                    this.contrastStatus = false
                    this.page = 1
                    this.getList()
                    // this.getCount()
                } else {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },

        async updateAnswer() {
            this.saveDisabled = true
            await this.$post(
                "/info/api/pc/information/answer/updateAnswer",
                {
                    ...this.formData,
                    answer: {
                        ...this.formData.answer,
                        startTime: this.$core.formatDate(new Date(this.$refs.affaris.questDate[0] || ""), "yyyy-MM-dd hh:mm:ss"),
                        endTime: this.$core.formatDate(new Date(this.$refs.affaris.questDate[1] || ""), "yyyy-MM-dd hh:mm:ss"),
                        bgColor: this.$refs.affaris.bgColor,
                        bgImg: this.$refs.affaris.$refs.imgData.uploadList && this.$refs.affaris.$refs.imgData.uploadList.length ? this.$refs.affaris.$refs.imgData.uploadList[0].url : "",
                        questionBankId: this.$refs.affaris.answer.questionBankId,
                        ruleVo: {
                            ...this.formData.ruleVo,
                            ...this.$refs.affaris.ruleVo,
                        },
                    },
                    pushChannelVo: {
                        ...this.formData.pushChannelVo,
                        pushType: this.formData.pushType,
                    },
                    prizeVos: this.$refs.affaris.ruleVo.luckyDraw && this.$refs.affaris.ruleVo.luckyDraw == "1" ? this.$refs.affaris.prizeVos : null,
                },
                { "Content-Type": "Application/json" }
            ).then(res => {
                this.saveDisabled = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改数据成功",
                        background: true,
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false
                    this.getList()
                    //   this.getCount()
                } else {
                    this.$Message.error({
                        content: "修改数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
